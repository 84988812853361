import React, { useState } from 'react';
import './App.css';

function App() {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');
  const [offensiveScore, setOffensiveScore] = useState(0); // State for Offensive Score
  const [jailScore, setJailScore] = useState(0); // State for Jail Score
  const [loading, setLoading] = useState(false); // State for loading

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the request starts
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const res = await fetch(`${backendUrl}/query`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query }),
    });
    const data = await res.json();
    setResponse(data.response);

    // Update the offensiveScore and jailScore based on the response
    const newOffensiveScore = parseInt(data.offensiveScore, 10); // Assuming the response contains an 'offensiveScore' field
    const newJailScore = parseInt(data.jailScore, 10); // Assuming the response contains a 'jailScore' field
    setOffensiveScore(newOffensiveScore);
    setJailScore(newJailScore);
    setLoading(false); // Set loading to false when the request completes
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= 280) {
      setQuery(value);
    }
  };

  const getColor = (value) => {
    const red = Math.min(255, Math.floor((value / 10) * 255));
    const green = Math.min(255, Math.floor(((10 - value) / 10) * 255));
    return `rgb(${red}, ${green}, 0)`;
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>
          Welcome to Post Protect, a playful platform designed to shed light on recent events surrounding online speech.
          In a time where the line between free expression and harmful content is hotly debated, this website takes a playful approach to assess the harmful content of your tweet.
          While we do not endorse offensive behavior, we aim to spark thoughtful discussions on the proportionality of punitive measures in the digital age.
        </p>
        <form onSubmit={handleSubmit}>
          <textarea
            value={query}
            onChange={handleInputChange}
            placeholder="Enter your query"
            rows="4"
          />
          <div className={`character-count ${query.length === 280 ? 'red' : ''}`}>
            Character count: {query.length}
          </div>
          {loading ? (
            <div className="spinner">Loading...</div> // Show spinner when loading
          ) : (
            <button type="submit">Submit</button>
          )}
        </form>
        <textarea
          className="response-box"
          value={response}
          readOnly
        />
        <div className="scale-graphic">
          <p>Offensive Score: {offensiveScore}</p>
          <div className="scale-bars">
            {[...Array(10)].map((_, i) => (
              <div
                key={i}
                className="scale-bar"
                style={{ backgroundColor: i < offensiveScore ? getColor(i + 1) : 'lightgray' }}
              ></div>
            ))}
          </div>
        </div>
        <div className="scale-graphic">
          <p>Years in Jail: {jailScore}</p>
          <div className="scale-bars">
            {[...Array(10)].map((_, i) => (
              <div
                key={i}
                className="scale-bar"
                style={{ backgroundColor: i < jailScore ? getColor(i + 1) : 'lightgray' }}
              ></div>
            ))}
          </div>
        </div>
      </header>
      <footer className="App-footer">
        <p>
          <strong>Disclaimer:</strong> This site is intended purely for entertainment and educational purposes. We do not condone hate speech or inflammatory content and urge all users to adhere to the guidelines and laws of their respective countries.
        </p>
      </footer>
    </div>
  );
}

export default App;